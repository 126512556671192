import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { CallbackCamera } from 'unico-webframe'
import { ROOT_URL } from '~/constants/index'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { CoursesContext, IModalProps } from '~/contexts/CoursesContext'
import { readSelfie, writeSelfie } from '~/services/api'
import { getUnicoShotMethodFromJWT } from '~/utils/getUnicoShotMethodFromJWT'
import { uploadToFirebase } from '~/utils/uploadToFirebase'
import { useWindowSize } from '~/utils/useWindowSize'
import { unicoConfig, unicoLayout } from './constants'

/**
 * @name useUnicoSelfie
 * @description custom hook for selfie screen
 */
interface VideoContraintsProps {
  width: { min: number }
  height: { min: number }
  aspectRatio: number
}

export const useUnicoSelfie = () => {
  const { step, isSendingData, partnerId } = useContext(CheckoutContext)
  const { modalRef, openModal } = useContext(CoursesContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [picture, setPicture] = useState('')
  const [encriptedLiveness, setEncriptedLiveness] = useState('')
  const [randomNumber, setRandomNumber] = useState<number>(1)
  const [isWebcamEnabled, setIsWebcamEnabled] = useState<boolean>(false)
  // @ts-ignore
  const [videoConstraints, setVideoContraints] = useState<VideoContraintsProps>({})
  const [modalMessage, setModalMessage] = useState('')
  const [unicoErrorObject, setUnicoErrorObject] = useState<any>()

  const { width } = useWindowSize()

  const unicoModalRef = useRef<IModalProps>()

  const forceRefresh = () => {
    setRandomNumber(Math.random())
    setIsWebcamEnabled(false)
    setIsLoading(false)
    setPicture('')
    setEncriptedLiveness('')
    setUnicoErrorObject(null)
  }

  useEffect(() => {
    if (width < 700) {
      setVideoContraints({
        facingMode: 'user',
        // @ts-ignore
        width: { ideal: 1920 },
        // @ts-ignore
        height: { ideal: 1080 },
      })

      return
    }

    // @ts-ignore
    setVideoContraints({})
  }, [width])

  /**
   * @name initUnico
   * @description function to open camera and capture user selfie
   */
  const initUnico = async (useFallbacks?: boolean) => {
    const callback: CallbackCamera = {
      on: {
        success: function (obj) {
          const hasBase64Headers = obj?.base64?.includes('data:image/jpeg;base64,')
          const base64 = hasBase64Headers ? obj.base64 : `data:image/jpeg;base64,${obj.base64}`

          setPicture(base64)
          setEncriptedLiveness(obj?.encrypted || '')
        },
        error: function (err) {
          setUnicoErrorObject(err)
          // v3 version returns a code 73706 when the camera was already being used
          if (err?.code === 73706 || err?.code === 73707) {
            initUnico(true)
            return
          }

          if (err?.type === 'PermissionError') {
            openModal()
            return
          }

          if (err?.type === 'SessionError' || err?.type === 'LifecicleError' || err?.type === 'LivenessException') {
            // restart unico because of timeout
            initUnico(true)
            return
          }

          setIsWebcamEnabled(true)

          toast.error(err.message || err, {
            toastId: 'initUnico',
            autoClose: 10000,
          })
        },
        support: function (err) {
          console.log('support: ', err)
          setIsWebcamEnabled(true)
        },
      },
    }

    const isDebug = localStorage.getItem('DEBUG_UNICO') === 'true'
    const isDebugV2 = localStorage.getItem('DEBUG_UNICO_V2') === 'true'
    const isDebugLiveness = localStorage.getItem('DEBUG_UNICO_LIVENESS') || null

    const shouldOpenFallback = isDebugLiveness === null && (useFallbacks || isDebugV2)

    if (shouldOpenFallback) {
      const acessoWebFrame = await import(
        // @ts-ignore
        'unico-webframe-legacy/dist/unico-webframe.esm'
      )

      return acessoWebFrame.webFrameModel
        .loadModelsCameraInteligence(`${window.location.origin}/models`)
        .then(() => {
          acessoWebFrame.initCamera(isDebug ? { TYPE: 1 } : unicoConfig, callback, unicoLayout)
        })
        .catch((e: any) => {
          toast.error(e.message || e, {
            toastId: 'initUnico',
            autoClose: 10000,
          })
        })
    }

    const { UnicoCheckBuilder, SelfieCameraTypes, UnicoThemeBuilder } = await import('unico-webframe')

    const unicoCameraBuilder = new UnicoCheckBuilder()

    unicoCameraBuilder.setModelsPath(`${window.location.origin}/models`)

    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#006eab')
      .setColorSilhouetteError('#DE0C4B')
      .setColorSilhouetteNeutral('#FFFFFF')
      .setBackgroundColor('#FFFFFF')
      .setColorText('#006eab')
      .setBackgroundColorComponents('#006eab')
      .setColorTextComponents('#FFFFFF')
      .setBackgroundColorButtons('#006eab')
      .setColorTextButtons('#FFFFFF')
      .setBackgroundColorBoxMessage('#FFFFFF')
      .setColorTextBoxMessage('#000')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregando sua câmera...</div>`,
      )
      .build()

    unicoCameraBuilder.setTheme(unicoTheme)

    const unicoCamera = unicoCameraBuilder.build()

    const subdomain = window?.location?.hostname?.split('.')?.[0] || 'pay'

    const cameraPromised = unicoCamera.prepareSelfieCamera(
      `/unico-credentials?subdomain=${subdomain}`,
      isDebug ? SelfieCameraTypes.NORMAL : SelfieCameraTypes.SMART,
    )
    cameraPromised
      .then((cameraOpener) => cameraOpener.open(callback))
      .catch((e: any) => {
        setUnicoErrorObject(e)
        useFallbacks ? setIsWebcamEnabled(true) : initUnico(true)
      })
  }

  /**
   * @name shootAgain
   * @description dispatch camera for user selfie and clear old base64 data
   */
  const shootAgain = () => {
    setPicture('')
    setUnicoErrorObject(null)
    initUnico()
  }

  /**
   * @name uploadFile
   * @description convert file to jpeg and upload to firebase
   */
  const uploadFile = async () => {
    setIsLoading(true)
    const { url, error } = await convertToFile(picture)
    if (!error) {
      const imageUrl = await uploadToFirebase(url, String(partnerId))
      const jwtUrl = encriptedLiveness && (await uploadToFirebase(encriptedLiveness, String(partnerId), true))

      if (imageUrl) {
        callWrite(imageUrl, jwtUrl, unicoErrorObject)
      } else {
        setIsLoading(false)
        toast.error('Erro ao subir sua selfie para o serviço de imagens.', {
          toastId: 'uploadFile',
        })
      }
    } else {
      toast.error('Erro ao converter sua selfie para o formato correto', {
        toastId: 'uploadFile',
      })
    }
  }

  /**
   * @name convertToFile
   * @description convert base64 data to jpeg file and return new data url
   */
  const convertToFile = async (
    file64: string,
  ): Promise<{
    url?: string
    error?: boolean
  }> => {
    return new Promise((resolve, reject) => {
      if (file64) {
        const image = new Image()
        image.src = file64
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          canvas.getContext('2d')?.drawImage(image, 0, 0)
          const dataURL = canvas.toDataURL('image/jpeg')

          if (dataURL) {
            resolve({ url: dataURL })
          } else {
            reject({ error: 'Erro ao converter arquivo' })
          }
        }
      } else {
        reject({ error: 'Base64 vazio' })
      }
    })
  }

  /**
   * @name callWrite
   * @description this method has 2 features
   * 1. send data to back end
   * 2. change context to the next screen
   */
  const callWrite = async (url: string, jwtUrl?: string, errorObject?: any) => {
    const shotMethod = getUnicoShotMethodFromJWT(encriptedLiveness)

    await writeSelfie({
      currentScreen: step,
      data: {
        url,
        typeExtension: 'JPEG',
        shotMethod,
        encriptedLiveness: jwtUrl || null,
        errorObject,
        version: ROOT_URL.UNICO_WEBFRAME_VERSION,
      },
    })

    forceRefresh()
  }

  useEffect(() => {
    const fetchSelfieScreen = async () => {
      const data = await readSelfie()

      if (typeof data !== 'string') {
        const { response } = data || {}

        if (response?.selfieStatus === 'firstTry') {
          typeof window !== undefined && initUnico(false)
          return
        }

        setModalMessage(response?.message || '')

        if (response?.message) {
          unicoModalRef?.current?.openModalFn?.()
        }

        return
      }

      initUnico(true)
    }

    fetchSelfieScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomNumber, step])

  return {
    picture,
    setPicture,
    uploadFile,
    isLoading,
    shootAgain,
    isWebcamEnabled,
    videoConstraints,
    isSendingData,
    modalRef,
    initUnico,
    unicoModalRef,
    modalMessage,
  }
}
